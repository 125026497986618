if (!window.console){ window.console = { log:function(msg){ }}; }
// @codekit-prepend jquery.colorbox-min.js;
// @codekit-prepend jquery.easing.1.3.js;
// @codekit-prepend ppt.jquery.utility.js;

//新規ウィンドウをオープンさせる
$.fn.blankWindow　=　function(className){
	var className = className || 'externalLink';
	$(this).addClass(className).on('click',function(){
		window.open(this.href, '_blank');
		return false;
	});
}

$(function(){

	$.support.transform  = typeof $("body").css("transform") === "string";
	$.support.transition = typeof $("body").css("transitionProperty") === "string";

	var page = {
		id 		: document.getElementsByTagName('body')[0].getAttribute('id'),
		classes	: false//document.getElementsByTagName('body')[0].getAttribute('class').split(' ')
	};

	//toTop
	//$('#toTop>a').toTop();

	//ページ内スクロール
	$('#sub a[href^=#],#container-header a[href^=#]').click(function(){
		$('html, body').animate({
				'scrollTop': $($(this).attr('href')).offset().top
			}, 600, 'easeInOutExpo');
		return false;
	});

	//禁則処理
	$('#container-footer').each(function(index, el) {
		var yakumonoHead = new RegExp('([「『（【［｛〈《〔])','g');
		var yakumonoFoot = new RegExp('([」』）】］｝〉》〕。、，])','g');
		//var kanaHead = new RegExp('(ト)','g');
		var html = $(this).html();
		html = html.replace(yakumonoHead,'<span class="yakumonoHead">$1</span>');
		html = html.replace(yakumonoFoot,'<span class="yakumonoFoot">$1</span>');
		//html = html.replace(kanaHead,'<span class="kanaHead">$1</span>');
		$(this).html(html);
	});

	//メニュー開閉
	$('#container-header-nav').each(function(index, element) {
		var $target = $(this);
		$(this).on('click',function(event){
			if($target.hasClass('open')){
				$target.removeClass('open');
			}else{
				$target.addClass('open');
				event.stopPropagation()
			}
		});
	});
	$('body').on('click',function(event){
		if($('#container-header-nav').hasClass('open')){
			$('#container-header-nav').removeClass('open');
		}
	});

	//pjax
    if ($.support.pjax) {
        $(document)
            .on('click', 'a[class!="externalLink"]', function(event) {
                event.preventDefault();
                var href = $(this).attr('href');
                // フェードアウトエフェクトの処理
                $('main').animate({
                    opacity : 0
                }, 'fast', function() {
                    // コールバックでpjaxを実行する
                    $.pjax({
                        url: href,
                        container : 'main',
                        fragment : 'main',
                        timeout : 10000
                    });
                });
            })
            .on('pjax:complete', function() {
				var location = window.location.pathname + window.location.search;
				ga('send', 'pageview', location);
            })
            .on('pjax:end', function() {
	page.id = location.pathname.replace(/\//g,'')||'home'
                $('body').attr('id', page.id);
                $('main').animate({ opacity : 1 }, 'fast');
                pageLoadedInit(page);
              });
    }

	//flat-height
	$('.item-list').on('flatHeight',function(){
		var target = this;
		this.maxHeight = 0;
		$(this).find('a').each(function(){
			var height = $(this).height();
			if(target.maxHeight < height) target.maxHeight = height;
		});
		$(this).find('a').height(this.maxHeight);
	});
	$(window).on('resize',function(){
		$('.item-list').trigger('flatHeight');
	});

    pageLoadedInit(page);

});//end jQuery

function pageLoadedInit(page){

	//flat-height
	$('.item-list').each(function(){
		var target = this;
		var $img = $(this).find('img');
		if($img.length){
			$img.bind("load", function(){
				$(target).trigger('flatHeight');
			});
		}else{
			$(target).trigger('flatHeight');
		}
	});

	//新規ウィンドウ
	var noBlank	= [
		'[href^="http://new-shop.lc/"]',
		'[href^="http://newshop-hmmt.com/"]',
		'[href^="http://test.newshop-hmmt.com"]'
	];
	$('a[href^="https://"]:not('+noBlank+'),a[href^="http://"]:not('+noBlank+')').blankWindow();
	$('a[href$="\.pdf"]').blankWindow('pdfLink');

	switch(page.id){

		case 'shop-info' :
			//calender
			var sources = [
				{
					name:'営業日',
					className:'open-date',
					googleCalendarId: 'ek3qgbh43gcdj6g5t8vbf06pl0@group.calendar.google.com',
					//old calendar API v2//feed:'https://www.google.com/calendar/feeds/ek3qgbh43gcdj6g5t8vbf06pl0@group.calendar.google.com/public/basic',
					color:'#552111',//Newshop Official Brown
					text:'#ffffff'
				},{
					name:'イベント',
					className:'event',
					googleCalendarId: '10jcc7tiqcu3kf10m7e58squ9g@group.calendar.google.com',
					//old calendar API v2//feed:'https://www.google.com/calendar/feeds/10jcc7tiqcu3kf10m7e58squ9g@group.calendar.google.com/public/basic',
					color:'#0d3a50',//navy
					text:'#ffffff'
				},{
					name:'休日',
					className:'holiday',
					googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
					//old calendar API v2//feed:'http://www.google.com/calendar/feeds/ja.japanese%23holiday%40group.v.calendar.google.com/public/basic',
					color:'#f09abb',//$Pink
					text:'#ffffff',
					isCache:true
				}
			];
			var calendarLoadMain = $.getScript('//cdnjs.cloudflare.com/ajax/libs/fullcalendar/2.2.5/fullcalendar.min.js');//&ctz=Asia/Tokyo追加版
			var calendarLoadGcal = function(){ return $.getScript('//cdnjs.cloudflare.com/ajax/libs/fullcalendar/2.2.5/gcal.js'); };
			var calendarLoad = calendarLoadMain.pipe(calendarLoadGcal);
			var sourceSet = function(data){
				var array = [];
				var obj = {};
				var i = 0;
				while(data[i]){
					obj.googleCalendarId = data[i].googleCalendarId;
					//obj.url = data[i].feed;
					obj.className = data[i].className;
					obj.borderColor = data[i].color;
					obj.backgroundColor = data[i].color;
					obj.textColor = data[i].text;
					if(data[i].idCache) obj.cache = true;
					array.push(obj);
					obj = {};
					i++;
				}
				return array;
			}
			calendarLoad.done(function(){
				console.log('calendar loaded');
				$('#calendar').fullCalendar({
					googleCalendarApiKey: 'AIzaSyCzDj3dJ6go9Y5lreCiovkoRHrBU4aAn2U',
					dayNames: ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
					dayNamesShort: ['日','月','火','水','木','金','土'],
					timeFormat:'H:mm',
					//defaultView: $('body').width()<=640?'basicWeek':'month',
					titleFormat: {
						month: '営業日・イベントカレンダー YYYY年 M月',
						week: '[YYYY年 ]M月 D日{ —[YYYY年 ][ M月] D日}',
						day: 'YYYY年 M月 D日 dddd'
					},
					buttonText: {
						today: '今日'
					},
					eventSources:sourceSet(sources),
					eventClick: function(calEvent, jsEvent, view) {
						if(calEvent.source.className == 'holiday' ) return false;
						var html = [];
						html.push('<div id="small-dialog" class="zoom-anim-dialog">');
						html.push('<h3>'+calEvent.title+'</h3>');
						html.push('<p class="address">'+calEvent.location+'</p>');
						html.push('<p class-"date">'+dateFormat(calEvent.start,'年','月','日')+' 〜 '+dateFormat(calEvent.end,'年','月','日')+'</p>');
						html.push('<pre>'+calEvent.description+'</pre>');
						if(calEvent.source.className != 'other' ) html.push('<p class="toContact"><a class="offerBtn" href="/contact/?date='+dateFormat(calEvent.start)+'&amp;class='+calEvent.source.className+'">このレッスンに申込む</a></p>');
						html.push('<p class="toGoogle"><a href="'+calEvent.url+'">Googleカレンダーで表示</a>（ログインが必要です）</p>');
						html.push('</div>');
						$.magnificPopup.open({
							items: {
								src: html.join(''),
								type: 'inline',
							},
							fixedContentPos: false,
							fixedBgPos: true,
							overflowY: 'auto',
							closeBtnInside: true,
							preloader: false,
							midClick: true,
							removalDelay: 300,
							mainClass: 'my-mfp-zoom-in'
						});
						return false;
					}
				});
				if(document.ontouchstart !== undefined) $('#calendar').addTouch();
			});

		break;

	}//end switch
}

var dateFormatters = {
	s	: function(d)	{ return d.getSeconds() },
	ss	: function(d)	{ return zeroPad(d.getSeconds()) },
	m	: function(d)	{ return d.getMinutes() },
	mm	: function(d)	{ return zeroPad(d.getMinutes()) },
	h	: function(d)	{ return d.getHours() % 12 || 12 },
	hh	: function(d)	{ return zeroPad(d.getHours() % 12 || 12) },
	H	: function(d)	{ return d.getHours() },
	HH	: function(d)	{ return zeroPad(d.getHours()) },
	d	: function(d)	{ return d.getDate() },
	dd	: function(d)	{ return zeroPad(d.getDate()) },
	M	: function(d)	{ return d.getMonth() + 1 },
	MM	: function(d)	{ return zeroPad(d.getMonth() + 1) },
	yy	: function(d)	{ return (d.getFullYear()+'').substring(2) },
	yyyy: function(d)	{ return d.getFullYear() },
	t	: function(d)	{ return d.getHours() < 12 ? 'a' : 'p' },
	tt	: function(d)	{ return d.getHours() < 12 ? 'am' : 'pm' },
	T	: function(d)	{ return d.getHours() < 12 ? 'A' : 'P' },
	TT	: function(d)	{ return d.getHours() < 12 ? 'AM' : 'PM' },
};
function zeroPad(n) {
	return (n < 10 ? '0' : '') + n;
}
function dateFormat(d,Y,M,D,T){
	f = dateFormatters;
	Y = Y || '.', M = M || '.', D = D || '.', T = T || ':'
	return f.yyyy(d)+Y+f.M(d)+M+f.d(d)+D+f.HH(d)+T+f.mm(d);
}
function CLocationSearch(sSource) {

	/* 初期化処理 */
	var sAry = new Array();	/* アイテム配列を追加 */
	{
		var nCur = 0, nRet = 0;
		var sSearch = sSource.substr(1);
		var sName, sValue;

		nRet = sSearch.indexOf('=', nCur)		// 最初の'='を検索
		while(nRet >= 0) {
			// '='以前が変数名
			sName = sSearch.substring(nCur, nRet);
			nCur = nRet + 1;

			// 値を取り出す
			nRet = sSearch.indexOf('&', nCur);	// 変数の区切りを検索
			if (nRet == -1) nRet = sSearch.length + 1;		// なければ最後まで
			sValue = sSearch.substring(nCur, nRet);

			// 配列に格納
			sAry[sName] = sValue;

			// 次を検索
			nCur = nRet + 1;
			nRet = sSearch.indexOf('=', nCur);
		}
	}

	/* 値 */
	this.valueOf = function () {
		return sSource;
	}

	/* 値を取り出すメソッド */
	this.item = function (sVarname) {
		try {
			return sAry[sVarname];	//あれば返す
		} catch(e) {
			return '';				//なければ空文字を返す
		}
	}

	/* 要素数 */
	this.length   = function () {
		return sAry.length;
	}

	/* toString */
	this.toString = function () {
		return this.length + '';
	}

}
